<template>
  <div class="container noColor" v-title :data-title="$t('i18n.elevatorManagement')" id="elevatorManagement">
    <el-card class="box-card">
      <template #header>
        <span>{{ $t("i18n.basicInfo") }}</span>
        <el-button class="fr" type="text" @click="$router.go(-1)">
          {{ $t("i18n.back") }}
        </el-button>
        <el-button v-has="'楼层信息管理'" v-if="!basicColumns[1].readonly" class="fr" type="text"
          @click="dialogMapVisible = true">
          {{ $t("i18n.mapLocation") }}
        </el-button>
        <el-button v-has="'楼层信息管理'" v-if="!isAdd" class="fr" type="text" @click="enterFloorPage">
          {{ $t("i18n.floorsInfo") }}
        </el-button>
        <el-button v-has="'编辑电梯'" v-if="!isAdd" class="fr" type="text" @click="
    setIsEdit('basicColumns', false);
  setIsEdit('companyColumns', false);
  ">
          {{ $t("i18n.edit") }}
        </el-button>
        <el-button v-has="'电梯故障推送设置'" v-if="!isAdd" class="fr" type="text" @click="enterPage">
          {{ $t("i18n.faultpush") }}
        </el-button>
      </template>
      <div class="text item">
        <jl-form :columns="basicColumns" @onSubmit="onSubmit" @resetForm="
    setIsEdit('basicColumns', true);
  setIsEdit('companyColumns', true);
  "></jl-form>
      </div>
    </el-card>
    <el-card class="box-card m-t" style="padding-bottom: 100px">
      <template #header>
        <span>{{ $t("i18n.relevantCompany") }}</span>
      </template>
      <div class="text item">
        <jl-form :columns="companyColumns" :option="[{ show: false }]"></jl-form>
      </div>
    </el-card>
    <el-dialog :title="$t('i18n.mapLocation')" v-model="dialogMapVisible" width="100%">
      <jl-map id="positionMap" @addPoint="addPoint" :region="form"></jl-map>
    </el-dialog>
  </div>
</template>

<script>
import { reactive, toRefs, getCurrentInstance } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import jlForm from "../../components/form";
import jlMap from "../../components/map";
export default {
  name: "AddEditElevator",
  components: {
    jlForm,
    jlMap,
  },
  setup(props, { emit }) {
    emit("public_header", true);
    const { proxy } = getCurrentInstance();
    const route = useRoute();
    const router = useRouter();
    const { t } = useI18n();
    const isEdit = route.query.id !== undefined;
    const state = reactive({
      paging: {
        page: 1,
        pageSize: 10,
      },
      form: {
        region: "",
        addr: "",
      },
      isAdd: route.query.id === undefined,
      basicColumns: [
        {
          prop: "equipmentNumber",
          label: "equipmentNo",
          type: "input",
          show: isEdit,
          readonly: isEdit,
        },
        {
          prop: "number",
          label: "elevatorNo",
          type: "input",
          readonly: isEdit,
          rules: [{ required: true, message: t("i18n.input") }],
        },
        {
          prop: "elevatorExtendDTO&usedUnitNo",
          label: "usedUnitNo",
          type: "input",
          readonly: isEdit,
        },
        {
          prop: "beaconNo",
          label: "beaconNo",
          type: "input",
          readonly: isEdit,
        },
        {
          prop: "juryNumber",
          label: "nineNo",
          type: "input",
          readonly: isEdit,
        },
        {
          prop: "fireAlarmNumber",
          label: "kunshanNo",
          type: "input",
          readonly: isEdit,
        },
        {
          prop: "alias",
          label: "projectAlias",
          type: "input",
          readonly: isEdit,
          rules: [{ required: true, message: t("i18n.input") }],
        },
        {
          prop: "projectName",
          label: "projectName",
          type: "input",
          readonly: isEdit,
          rules: [{ required: true, message: t("i18n.input") }],
        },
        {
          prop: "regCode",
          label: "registrationCode",
          type: "input",
          readonly: isEdit,
        },
        {
          prop: "elevatorVariety",
          label: "elevatorType",
          type: "select",
          props: { label: "code", value: "value" },
          data: [],
          uploadUrl: "elevator_variety",
          readonly: isEdit,
          rules: [{ required: true, message: t("i18n.select") }],
        },
        {
          prop: "elementId",
          label: "elementId",
          type: "input",
          readonly: isEdit,
        },
        {
          prop: "intelHardwareNumber",
          label: "intelligenceCode",
          type: "input",
          readonly: isEdit,
        },
        {
          prop: "controllerType",
          label: "controllerType",
          type: "input",
          readonly: isEdit,
        },
        {
          prop: "station",
          label: "layerStationDoor",
          type: "input",
          placeholder: t("i18n.stationFormat"),
          readonly: isEdit,
          rules: [
            { required: true, message: t("i18n.input") },
            {
              pattern: /^[1-9]\d{0,1}\/[1-9]\d{0,1}\/[1-9]\d{0,1}$/,
              message: t("i18n.stationNot"),
              trigger: "blur",
            },
          ],
        },
        {
          prop: "brandId",
          label: "elevatorBrand",
          type: "select",
          props: { label: "name", value: "id" },
          data: [],
          readonly: isEdit,
        },
        {
          prop: "dutyPhone",
          label: "hourLineOnDuty",
          type: "input",
          readonly: isEdit,
        },
        {
          prop: "usageType",
          label: "useOccasion",
          type: "select",
          props: { label: "code", value: "value" },
          data: [],
          readonly: isEdit,
          rules: [{ required: true, message: t("i18n.select") }],
        },
        {
          prop: "ratedWeight",
          label: "ratedLoad",
          type: "input",
          readonly: isEdit,
        },
        {
          prop: "ratedSpeed",
          label: "ratedSpeed",
          type: "input",
          readonly: isEdit,
        },
        {
          prop: "controlMode",
          label: "controlMode",
          type: "select",
          props: { label: "locale", value: "id" },
          data: [
            { locale: "collectiveSelective", id: "1" },
            { locale: "parallel", id: "2" },
          ],
          readonly: isEdit,
        },
        {
          prop: "hoistingHeight",
          label: "hoistingHeight",
          type: "input",
          readonly: isEdit,
        },
        {
          prop: "pleaseSelect",
          label: "pleaseSelect",
          type: "cascader",
          checkStrictly: false,
          data: proxy.$defined.getProvinceData(),
          readonly: isEdit,
          change: (val) => {
            let obj = {
              province: val[0],
              city: val[1],
              district: val[2],
            };
            state.form.region = getRegionByParams(obj, "value").join("");
          },
        },
        {
          prop: "address",
          label: "address",
          type: "input",
          readonly: isEdit,
          change: (val) => {
            state.form.addr = val;
          },
        },
        {
          prop: "lng",
          label: "longitude",
          type: "input",
          readonly: isEdit,
        },
        {
          prop: "lat",
          label: "latitude",
          type: "input",
          readonly: isEdit,
        },
        {
          prop: "elevatorModel",
          label: "elevatorModel",
          type: "input",
          readonly: isEdit,
        },
        {
          prop: "elevatorExtendDTO&productNo",
          label: "productNo",
          type: "input",
          readonly: isEdit,
        },
        {
          prop: "elevatorExtendDTO&deviceCode",
          label: "deviceCode",
          type: "input",
          readonly: isEdit,
        },
        {
          prop: "elevatorExtendDTO&factoryNo",
          label: "factoryNo",
          type: "input",
          readonly: isEdit,
        },
        {
          prop: "elevatorExtendDTO&driveMode",
          label: "driveMode",
          type: "input",
          readonly: isEdit,
        },
        {
          prop: "elevatorExtendDTO&doorOpenMode",
          label: "doorOpenMode",
          type: "input",
          readonly: isEdit,
        },
        {
          prop: "elevatorExtendDTO&nominalSpeed",
          label: "nominalSpeed",
          type: "input",
          readonly: isEdit,
        },
        {
          prop: "elevatorExtendDTO&nominalWidth",
          label: "nominalWidth",
          type: "input",
          readonly: isEdit,
        },
        {
          prop: "elevatorExtendDTO&tiltAngle",
          label: "tiltAngle",
          type: "input",
          readonly: isEdit,
        },
        {
          prop: "elevatorExtendDTO&mainPower",
          label: "mainPower",
          type: "input",
          readonly: isEdit,
        },
        {
          prop: "elevatorExtendDTO&sectionLength",
          label: "sectionLength",
          type: "input",
          readonly: isEdit,
        },
        {
          prop: "elevatorExtendDTO&cylinderNum",
          label: "cylinderNum",
          type: "input",
          readonly: isEdit,
        },
        {
          prop: "elevatorExtendDTO&jackingType",
          label: "jackingType",
          type: "input",
          readonly: isEdit,
        },
        {
          prop: "elevatorExtendDTO&id",
          label: "id",
          type: "input",
          show: false,
        },
        {
          prop: "agentName",
          label: "agent",
          type: "input",
          readonly: isEdit,
        },
        {
          prop: "productionTime",
          label: "productionDate",
          type: "date",
          readonly: isEdit,
        },
        {
          prop: "deliverTime",
          label: "deliveryTime",
          type: "date",
          readonly: isEdit,
        },
        {
          prop: "elevatorExtendDTO&annualInspectionDate",
          label: "lastAnnualInspectionDate",
          type: "date",
          readonly: isEdit,
        },
        {
          prop: "moduleType",
          label: "moduleType",
          type: "select",
          multiple: true,
          props: { label: "code", value: "value" },
          data: [],
          uploadUrl: "web_socket_type",
          readonly: isEdit,
        },
        {
          prop: "thirdPartyId",
          label: "qualitySupervisionBureau",
          type: "select",
          props: { label: "description", value: "caseName" },
          data: [],
          readonly: isEdit,
        },
        {
          prop: "devUseNo",
          label: "EUCRN",
          type: "input",
          readonly: isEdit,
          rules: [{ pattern: /^[a-zA-Z0-9()\u4e00-\u9fa5]+$/, message: '请輸入正确的括号格式', trigger: 'blur' }],
        },
        {
          prop: "ipcDeviceSerial",
          label: "elevatorIP",
          type: "input",
          readonly: isEdit,
        },
      ],
      elevatorDetail: "",
      companyColumns: [
        {
          prop: "userCompanyId",
          label: "buildingUsingParty",
          type: "remote",
          readonly: isEdit,
          props: { label: "name", value: null },
          loading: false,
          data: [],
          remoteMethod: (val) => {
            remoteCompanyMethod(val, "companyColumns", "userCompanyId");
          },
          change: (val) => {
            state.unitObj.userCompanyId = val;
          },
        },
        {
          prop: "installCompanyId",
          label: "installationCompany",
          type: "remote",
          readonly: isEdit,
          props: { label: "name", value: null },
          loading: false,
          data: [],
          remoteMethod: (val) => {
            remoteCompanyMethod(val, "companyColumns", "installCompanyId");
          },
          change: (val) => {
            state.unitObj.installCompanyId = val;
          },
        },
        {
          prop: "manufacturerId",
          label: "manufacturingCompany",
          type: "remote",
          readonly: isEdit,
          props: { label: "name", value: null },
          loading: false,
          data: [],
          remoteMethod: (val) => {
            remoteCompanyMethod(val, "companyColumns", "manufacturerId");
          },
          change: (val) => {
            state.unitObj.manufacturerId = val;
          },
        },
        {
          prop: "ownerCompanyId",
          label: "propertyCompany",
          type: "remote",
          readonly: isEdit,
          props: { label: "name", value: null },
          loading: false,
          data: [],
          remoteMethod: (val) => {
            remoteCompanyMethod(val, "companyColumns", "ownerCompanyId");
          },
          change: (val) => {
            state.unitObj.ownerCompanyId = val;
          },
        },
        {
          prop: "regulatorId",
          label: "regulator",
          type: "remote",
          readonly: isEdit,
          props: { label: "name", value: null },
          loading: false,
          data: [],
          remoteMethod: (val) => {
            remoteCompanyMethod(val, "companyColumns", "regulatorId");
          },
          change: (val) => {
            state.unitObj.regulatorId = val;
          },
        },
        {
          prop: "repairCompanyId",
          label: "repairCompany",
          type: "remote",
          readonly: isEdit,
          props: { label: "name", value: null },
          loading: false,
          data: [],
          remoteMethod: (val) => {
            remoteCompanyMethod(val, "companyColumns", "repairCompanyId");
          },
          change: (val) => {
            state.unitObj.repairCompanyId = val;
          },
        },
        {
          prop: "maintainerId",
          label: "maintenanceCompany",
          type: "remote",
          readonly: isEdit,
          props: { label: "name", value: null },
          loading: false,
          data: [],
          remoteMethod: (val) => {
            remoteCompanyMethod(val, "companyColumns", "maintainerId");
          },
          change: (val) => {
            state.unitObj.maintainerId = val;
          },
        },
        {
          prop: "maintenanceManager",
          label: "manager",
          type: "input",
          readonly: isEdit,
          change: (val) => {
            state.unitObj.maintenanceManager = val;
          },
        },
        {
          prop: "maintenanceName",
          label: "maintenancePerson",
          type: "input",
          readonly: isEdit,
          change: (val) => {
            state.unitObj.maintenanceName = val;
          },
        },
      ],
      dialogMapVisible: false,
      unitObj: {},
    });

    const init = async () => {
      if (!state.isAdd) {
        let id = route.query.id;
        let { data } = await proxy.$api.system.getElevatorDetail(id);
        state.elevatorDetail = data;
        if (state.elevatorDetail.moduleType) {
          state.elevatorDetail.moduleType = state.elevatorDetail.moduleType.split(",")
        }
        console.log(state.elevatorDetail, "state.elevatorDetail");
        let obj = {
          province: data.provinceId,
          city: data.cityId,
          district: data.regionId,
        };
        state.form.region = getRegionByParams(obj, "value").join("");
        state.form.addr = data.address;
        setIsEdit("basicColumns", true, data);
        setIsEdit("companyColumns", true, data);
      }
    };

    const onSubmit = async (row) => {
      for (let key in state.unitObj) {
        row[key] = state.unitObj[key];
      }
      let data = dealData(row);
      if (data.moduleType) {
        data.moduleType = data.moduleType.join(",")
      }
      data.pushable = state.elevatorDetail.pushable
      data.id
        ? await proxy.$api.system.editElevator(data)
        : await proxy.$api.system.addElevator(data);
      let tip = data.id ? "edit" : "new";
      proxy.$defined.tip(t("i18n." + tip) + t("i18n.success"), "success");
      init()
      router.go(-1);
    };

    const dealData = (data) => {
      data.elevatorExtendDTO = data.elevatorExtendDTO || {};
      let ids = [
        "installCompanyId",
        "userCompanyId",
        "maintainerId",
        "manufacturerId",
        "ownerCompanyId",
        "regulatorId",
        "repairCompanyId",
      ];
      for (let key in data) {
        if (key.indexOf("&") > -1) {
          if (data[key] instanceof Date) {
            data[key] = proxy.$defined.format(data[key]);
          }
          data.elevatorExtendDTO[key.split("&")[1]] = data[key];
          delete data[key];
        }
        if (ids.indexOf(key) > -1) {
          data[key] instanceof Object
            ? (data[key] = data[key].id)
            : delete data[key];
        }
        if (data[key] instanceof Date) {
          data[key] = proxy.$defined.format(data[key]);
        }
        if (key === "pleaseSelect" && data[key] instanceof Array) {
          data.provinceId = data[key][0];
          data.cityId = data[key][1];
          data.regionId = data[key][2];
        }
      }
      if (route.query.flag == 1 && route.query.id) {
        data.id = route.query.id;
        data.elevatorExtendDTO.elevatorId = data.id;
      }
      return data;
    };

    const setIsEdit = (column, flag, data) => {
      state[column].map((item) => {
        if (item.prop !== "equipmentNumber") {
          item.readonly = flag;
        }
        item.isUpdate = true;
        if (!data) {
          return;
        }
        if (item.prop.indexOf("&") > -1) {
          item.value = data[item.prop.split("&")[0]][item.prop.split("&")[1]];
        } else if (item.prop === "pleaseSelect") {
          item.value = [data.provinceId, data.cityId, data.regionId];
        } else if (
          data &&
          data[item.prop] != null &&
          data[item.prop] != undefined
        ) {
          if (item.type === "remote") {
            let key = item.prop.split("Id")[0] + "Name";
            item.value = data[key];
          } else {
            item.value = data[item.prop];
          }
        }
      });
    };

    const getBrandData = async () => {
      let { data } = await proxy.$api.monitor.brandsAll({ name: "" });
      state.basicColumns.map((item) => {
        if (item.prop === "brandId") {
          item.data = data;
        }
      });
    };
    getBrandData();

    const getUsageTypeData = async () => {
      let { data } = await proxy.$api.common.getLineageByCode("usage_type");
      state.basicColumns.map((item) => {
        if (item.prop === "usageType") {
          item.data = data.lineages;
        }
      });
    };
    getUsageTypeData();

    const getThirdPartyId = async () => {
      let { data } = await proxy.$api.system.getQualitySupervision();
      state.basicColumns.map((item) => {
        if (item.prop === "thirdPartyId") {
          item.data = data;
        }
      });
    };
    getThirdPartyId();

    const remoteCompanyMethod = (val, column, key) => {
      var index = state[column].findIndex((item) => {
        return item.prop === key;
      });
      if (val) {
        state[column][index].loading = true;
        state[column].map((item) => {
          item.isUpdate = false;
        });
        setTimeout(() => {
          proxy.$api.system.getAllCompany({ name: val }).then((data) => {
            state[column][index].data = data.data;
            state[column][index].loading = false;
          });
        }, 1000);
      } else {
        state[column][index].data = [];
      }
    };

    const enterFloorPage = () => {
      router.push({
        path: "/elevator/floorCoU",
        query: { id: route.query.id },
      });
    };

    const enterPage = () => {
      router.push({
        path: "/elevator/faultCode",
        query: { id: route.query.id },
      });
    };

    const addPoint = (data) => {
      console.log(data, "1111111");
      state.dialogMapVisible = false;
      if (data != "") {
        state.basicColumns.map((item) => {
          item.isUpdate = false;
          if (item.prop === "lng") {
            item.isUpdate = true;
            item.value = data.point.lng;
          } else if (item.prop === "lat") {
            item.isUpdate = true;
            item.value = data.point.lat;
          } else if (item.prop === "pleaseSelect") {
            item.isUpdate = true;
            item.value = getRegionByParams(data.addressComponents, "label");
          } else if (item.prop === "address") {
            let title = data.title || "";
            item.isUpdate = true;
            item.value = data.address + title;
          }
        });
      }
      console.log(state.basicColumns);
    };

    const getRegionByParams = (obj, key) => {
      if (obj.province && obj.city && obj.district) {
        let areaData = proxy.$defined.getProvinceData();
        let province = areaData.filter((item) => item[key] == obj.province)[0];
        let city = province.children.filter((item) => item[key] == obj.city)[0];
        let district = city.children.filter(
          (item) => item[key] == obj.district
        )[0];
        console.log(province, city, district);
        if (province && city && district) {
          let reserve = key === "label" ? "value" : "label";
          return [province[reserve], city[reserve], district[reserve]];
        } else {
          return [];
        }
      } else {
        return [];
      }
    };

    init();

    return {
      ...toRefs(state),
      onSubmit,
      setIsEdit,
      remoteCompanyMethod,
      enterFloorPage,
      enterPage,
      addPoint,
      getRegionByParams,
    };
  },
};
</script>

<style lang="scss">
#elevatorManagement {
  .el-form:not(.el-form--inline) {
    .el-form-item {
      &:nth-child(23) {
        width: 66%;

        .el-form-item__content {
          width: 80%;
        }
      }
    }
  }

  .form-button,
  .form-button .el-form-item__content {
    width: 100%;
    margin: 0 !important;
    text-align: center;
  }

  .form-button {
    position: fixed;
    bottom: 40px;
    left: 0;
    z-index: 9;
  }

  .el-dialog {
    height: 100%;
    margin: 0 !important;

    .el-dialog__header {
      display: none;
    }

    .el-dialog__body {
      height: 100%;
      padding: 0;

      #positionMap {
        height: 100%;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  #elevatorManagement {
    margin: -20px 5px 0;
  }
}
</style>
