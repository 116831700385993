<template>
  <div id="map">
    <div id="jlMap" style="height: 100%"></div>
    <el-select
      v-model="address"
      filterable
      remote
      value-key="uid"
      reserve-keyword
      :placeholder="$t('i18n.search')"
      :remote-method="remoteMethod"
      :loading="loading"
      @change="setLocation"
    >
      <el-option
        v-for="item in options"
        :key="item.uid"
        :label="item.title"
        :value="item"
      ></el-option>
    </el-select>
    <div class="footer">
      <el-button class="el-button--cancel" @click="addPoint(0)">
        {{ $t("i18n.cancel") }}
      </el-button>
      <el-button type="primary" @click="addPoint">
        {{ $t("i18n.confirm") }}
      </el-button>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, onMounted } from "vue";
// import { gcj02tobd09, wgs84togcj02 } from "../../public/js/map";
import BMapGL from "BMap";
export default {
  name: "map",
  props: {
    region: {
      type: Object,
      default: () => {},
    },
  },
  emits: ["addPoint"],
  setup(props, { emit }) {
    emit("public_header", true);
    // const { proxy } = getCurrentInstance();
    const state = reactive({
      jlMap: null,
      address: "",
      loading: false,
      options: "",
      result: "",
    });

    const initMap = () => {
      state.address = props.region.addr;
      if (state.jlMap === null) {
        var map = (state.jlMap = new BMapGL.Map("jlMap"));
        map.enableScrollWheelZoom(true);
        map.enableDragging();
        if (state.address) {
          remoteMethod(state.address, 1);
        } else {
          var geolocation = new BMapGL.LocalCity();
          geolocation.get(function (r) {
            if (r) {
              map.centerAndZoom(
                new BMapGL.Point(r.center.lng, r.center.lat),
                12
              );
            }
          });
        }
        map.addEventListener("click", function (e) {
          onClickEvent(e.point);
        });
      }
    };

    const remoteMethod = (query, flag) => {
      if (query !== "") {
        state.loading = true;
        setTimeout(() => {
          var map = state.jlMap;
          var local = new BMapGL.LocalSearch(map, {
            onSearchComplete: function (results) {
              console.log(results)
              state.loading = false;
              state.options = [];
              for (var i = 0; i < results.getCurrentNumPois(); i ++){ state.options.push(results.getPoi(i)); }
              // state.options = results ? results.Br : [];
              if (flag === 1) {
                onClickEvent(state.options[0].point);
              }
            },
          });
          local.search(query);
        }, 200);
      } else {
        state.loading = false;
        state.options = [];
      }
    };

    const setLocation = () => {
      // 搜索后地图定位
      onClickEvent(state.address.point, state.address.title);
    };

    const onClickEvent = (p, address) => {
      var map = state.jlMap;
      var pt = new BMapGL.Marker(p);
      map.setViewport([p]);
      var geoc = new BMapGL.Geocoder();
      map.clearOverlays();
      map.addOverlay(pt);
      pt.setAnimation("BMAP_ANIMATION_BOUNCE");
      var pt2 = p;
      geoc.getLocation(pt2, function (rs) {
        state.result = address ? state.address : rs;
        state.result.addressComponents = rs.addressComponents;
        // state.address = rs.address;
      });
    };

    const addPoint = (flag) => {
      if (flag === 0) {
        state.result = "";
      }
      emit("addPoint", state.result);
    };

    onMounted(() => {
      initMap();
    });

    return {
      ...toRefs(state),
      remoteMethod,
      setLocation,
      addPoint,
    };
  },
};
</script>

<style lang="scss">
#map {
  height: 100%;
}
#positionMap {
  .el-select {
    position: absolute;
    top: 50px;
    left: 40px;
    min-width: $width * 2 + 40;
    width: 40%;
  }
  .footer {
    position: absolute;
    padding: 10px 20px 20px;
    top: 0;
    right: 0;
    z-index: 2;
  }
}
</style>
